@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#sell-this-now-modal {
  $width: 514px;

  width: $width;
  max-width: $width;
  z-index: 100;
  position: fixed;
  top: 55px;
  left: calc(50% - #{$width / 2});
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  #close-button {
    padding: 1rem;
  }

  .stn-header {
    display: block;
    font-size: 27px;
    font-weight: bold;
  }
  .modal-content {
    background-color: #fff !important;
  }
  .fp-expalanation {
    display: inline-block;
    height: 18px;
    margin: 0 1ch;
    vertical-align: bottom;
    width: 14px;
    cursor: pointer;
    background-color: transparent;
    background-image: url(/media/shared/common/icons/icon-question.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 14px 14px;
    color: rgba(0, 0, 0, 0);
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
  }
  .error-list {
    .btn {
      vertical-align: baseline;
    }
  }
  #form-group-promised-handling-time {
    padding: {
      left: 11px !important;
      right: 11px !important;
    }
    width: 50px;
  }
  @include media-breakpoint-down(sm) {
    width: 90vw;
    max-height: 90vh;
    left: 0;
    top: 5vh;
    margin-left: 5vw;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25);
  }
}
