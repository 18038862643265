@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.quickSearchContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;

  .quickSearchSVG {
    width: 21px;
    height: 19px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none"><path d="M1.75 15.125C1.75 16.1797 2.57031 17 3.625 17H19.875C20.1875 17 20.5 17.3125 20.5 17.625C20.5 17.9766 20.1875 18.25 19.875 18.25H3.625C1.86719 18.25 0.5 16.8828 0.5 15.125V1.375C0.5 1.0625 0.773438 0.75 1.125 0.75C1.4375 0.75 1.75 1.0625 1.75 1.375V15.125ZM13.625 4.5H18.625C18.9375 4.5 19.25 4.8125 19.25 5.125V10.125C19.25 10.4766 18.9375 10.75 18.625 10.75C18.2734 10.75 18 10.4766 18 10.125V6.64844L13.4297 11.2188C13.1953 11.4531 12.7656 11.4531 12.5312 11.2188L9.21094 7.89844L5.30469 11.8438C5.07031 12.0781 4.64062 12.0781 4.40625 11.8438C4.17188 11.6094 4.17188 11.1797 4.40625 10.9453L8.78125 6.57031C9.01562 6.33594 9.44531 6.33594 9.67969 6.57031L13 9.89062L17.1016 5.75H13.625C13.2734 5.75 13 5.47656 13 5.125C13 4.8125 13.2734 4.5 13.625 4.5Z" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .quickSearchLink {
    display: flex;
    padding: 4px 11px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--textColor, #fff);
    background: #4385f6b3;
    color: var(--textColor, #fff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    transition: all 0.2s ease-in-out;
    text-wrap: nowrap;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: var(--backgroundColor, #4385f6);
      border: 1px solid var(--textColor, #fff);
      background: var(--textColor, #fff);
      text-decoration: none;
    }
  }

  .swiper {
    margin-left: 0px;
  }

  .swiper-arrow {
    cursor: pointer;
  }

  .swiper-arrow-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 15px;

    &:before {
      content: "";
      display: block;
      border-top: 2px solid $vertical-color-85;
      border-left: 2px solid $vertical-color-85;
      width: 10px;
      height: 10px;
    }
  }

  .swiper-arrow-not-primary {
    height: 36px;
  }

  .swiper-arrow-prev {
    margin-right: 0.5em;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(-45deg);
    }
  }

  .swiper-arrow-next {
    margin-left: 0.5em;
    position: relative;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(135deg);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: unset;
  }
}

#header-secondary-swiper {
  .swiper-slide {
    width: auto;
  }

  .swiper-button-disabled {
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: unset;
  }

  .swiper-arrow {
    cursor: pointer;
  }

  .swiper-arrow-style {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 15px;

    &:before {
      content: "";
      display: block;
      border-top: 2px solid $vertical-color-85;
      border-left: 2px solid $vertical-color-85;
      width: 10px;
      height: 10px;
    }
  }

  .swiper-arrow-not-primary {
    height: 36px;
  }

  .swiper-arrow-prev {
    margin-right: 0.5em;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(-45deg);
    }
  }

  .swiper-arrow-next {
    margin-left: 0.5em;
    position: relative;
    transition: 0.5s ease-in-out;

    &:before {
      transform: rotate(135deg);
    }
  }

  .swiper-button-disabled {
    opacity: 0;
    transition: 0.5s ease-in-out;
    cursor: unset;
    min-width: 0px;
    margin: 0px;
    width: 0px;
  }
}
