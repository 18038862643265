@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#footer {
  background-color: $primary;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    color: #ffffff;
    font-size: $font-size-sm;
    li {
      padding-bottom: 10px;
      a {
        color: rgba(255, 255, 255, 0.7);
        font-weight: $font-weight-light;
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
      strong {
        font-weight: $font-weight-bold;
      }
    }
  }
  > div {
    #customer-service-callout {
      grid-area: cs-callout;
      color: #ffffff;
    }
    #vertical-links {
      grid-area: vertical-links;
    }
    #corporate-info {
      grid-area: corp-info;
    }
    #my-account-footer-links {
      grid-area: my-account-footer;
    }
    #let-us-help-links {
      grid-area: let-us-help;
    }
    #copyright-info {
      grid-area: copyright;
      p {
        font-size: 11px;
        line-height: 20px;
        margin: 0;
        color: rgba(255, 255, 255, 0.5);
        a {
          color: rgba(255, 255, 255, 0.5) !important;
          &:hover {
            color: rgba(255, 255, 255, 0.5) !important;
            text-decoration: none !important;
          }
        }
      }
    }
    #trust-badges {
      grid-area: badges;
      div.google-site-reviews-badge > div > div > div.branding {
        display: none !important;
        height: 0px !important;
        max-height: 0 !important;
        width: 0 !important;
        max-width: 0 !important;
        overflow: hidden !important;
        text-indent: 1000% !important;
        opacity: 0 !important;
      }
      #bbblink {
        display: block;
        position: relative;
        overflow: hidden;
        width: 150px;
        height: 57px;
        margin: 0px;
        padding: 0px;
      }
      #bbblinkimg {
        padding: 0px;
        border: none;
      }
    }
    #social-media-links {
      grid-area: social-media;
    }
    #mobile-footer-links {
      grid-area: mobile-footer-links;
      display: none;
    }
    .mobile-customer-service-callout {
      grid-area: mobile-customer-service-callout;
    }
    .mobile-my-account-footer-link {
      grid-area: mobile-my-account-footer;
    }
    .mobile-order-history-footer-link {
      grid-area: mobile-order-history-footer;
    }
    .mobile-auto-order-footer-link {
      grid-area: mobile-auto-order-footer;
    }
    .mobile-reorder-footer-link {
      grid-area: mobile-reorder-footer;
    }
    .mobile-vendor-footer-link {
      grid-area: mobile-vendor-footer;
    }
    .mobile-top-sellers-footer-link {
      grid-area: mobile-top-sellers-footer;
    }
    .mobile-contact-us-footer-link {
      grid-area: mobile-contact-us-footer;
    }
    .mobile-terms-footer-link {
      grid-area: mobile-terms-footer;
    }
    .mobile-privacy-footer-link {
      grid-area: mobile-privacy-footer;
    }
    .mobile-privacy-settings-link {
      grid-area: mobile-privacy-settings;
    }
    .mobile-dont-sell-info-footer-link {
      grid-area: mobile-dont-sell-info-footer;
    }

    #subscribe-to-email {
      grid-area: subscribe;
      position: relative;
      background-color: #ffffff;
      border-radius: 4px;
      overflow: hidden;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 44px 52px 1fr;
      grid-template-areas:
        "title"
        "subtext"
        "form";
      .h5 {
        grid-area: title;
        display: block;
        margin: 0;
      }
      p {
        grid-area: subtext;
        margin: 0;
      }
      .form-control {
        border: 0px;
        border-bottom: 2px solid $primary;
        font-weight: 300;
        padding: {
          left: 0;
          right: 0;
        }
      }
      #email-subscribe-form-footer {
        grid-area: form;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        #email-subscribe-form-footer-button-group {
          // padding-top:30px;
          display: grid;
          grid-template-columns: 95px auto;
          grid-template-rows: auto;
          grid-template-areas: "button messages";

          > div {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
          }
          .subscribe-button-container {
            grid-area: button;
            #submit-email-subscription-footer {
              padding: {
                top: 6px;
                bottom: 6px;
              }
            }
          }
          .subscribe-messages {
            grid-area: messages;
            padding-left: 5px;
            align-items: center;
          }
        }
      }
      #subscribe-to-email-success {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $savings-color;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: {
          top: 20px;
          bottom: 20px;
        }
        visibility: visible;
        opacity: 1;
        transition:
          opacity 300ms ease-in 100ms,
          visibility 0ms ease-in 0ms;
        span {
          display: block;
          color: #ffffff;
          font-size: 20px;
        }
        &[data-show-success="false"] {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }
  .social-media-footer-icons {
    width: 190px;
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      overflow: hidden;
      text-indent: 200%;

      background-size: 22px 22px;
      height: 22px;
      width: 22px;
    }

    .facebook-link {
      background-image: url("/media/dental/common/icons/social-facebook.png");
    }

    .twitter-link {
      background-image: url("/media/dental/common/icons/social-twitter.png");
    }

    .linkedin-link {
      background-image: url("/media/dental/common/icons/social-linkedin.png");
    }

    .youtube-link {
      background-image: url("/media/dental/common/icons/social-youtube.png");
    }

    .pinterest-link {
      background-image: url("/media/dental/common/icons/social-pinterest.png");
    }

    .reddit-link {
      background-image: url("/media/dental/common/icons/social-reddit.png");
    }
  }
  .phoneNumber {
    color: #fff;
  }
}

@include media-breakpoint-up(xl) {
  #footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    $footer-max-width: 1300px;
    #footer-ready-to-get-started-row {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      background-color: #f1f6fe;
      padding: 52px 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding: {
        left: 20px;
        right: 20px;
      }
      #footer-ready-to-get-started-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: $footer-max-width;
        h2 {
          color: $true-black;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          padding: 0 0 4px;
        }
        .get-started-cta {
          margin-left: auto;
          a {
            font-size: 18px;
            line-height: 24px;
            padding: {
              left: 32px;
              right: 32px;
            }
            max-height: 46px;
            // padding: 10px 32px;
          }
        }
      }
    }
    > div:not(#footer-ready-to-get-started-row) {
      padding: {
        left: 20px;
        right: 20px;
      }
      margin: {
        left: auto;
        right: auto;
      }
      padding-top: 48px;
      max-width: $footer-max-width;
      width: 100%;
      display: grid;
      grid-template-columns: 425px 1fr 1fr 1fr 1fr;
      grid-template-rows: 50px auto min-content auto;
      grid-template-areas:
        "subscribe cs-callout     cs-callout cs-callout        cs-callout"
        "subscribe vertical-links corp-info  my-account-footer let-us-help"
        "badges    badges         badges     badges            badges"
        "copyright copyright      copyright  copyright         social-media";
      > ul {
        padding-top: 24px;
        padding-bottom: 15px;
      }
      #subscribe-to-email {
        background-color: #ffffff;
        width: 375px;
        max-height: 240px;
        padding: 24px;
      }
      #customer-service-callout {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      }
      #trust-badges {
        padding-top: 32px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);

        .bbb-badge {
          padding-bottom: 26px;
          margin-left: 32px;
          /* This is to undo an issue with the webflow footer */
          img {
            max-width: none !important;
          }
        }

        div.google-site-reviews-badge {
          padding-bottom: 24px;
        }
      }
      #copyright-info,
      #social-media-links {
        padding-bottom: 10px;
      }
      #social-media-links {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
@include media-breakpoint-between(md, xl) {
  #footer {
    flex-direction: column;
    justify-content: center;
    #footer-ready-to-get-started-row {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      background-color: #f1f6fe;
      padding: 52px 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding: {
        left: 20px;
        right: 20px;
      }
      #footer-ready-to-get-started-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h2 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          padding: 0 0 4px;
        }
        .get-started-cta {
          margin-left: auto;
          a {
            font-size: 18px;
            line-height: 24px;
            padding: {
              left: 32px;
              right: 32px;
            }
            max-height: 46px;
            // padding: 10px 32px;
          }
        }
      }
    }
    #copyright-info {
      border-top: 1px solid $footer-horizontal-divider-color;
      padding-top: 24px;
      margin-top: 24px;
      grid-area: copyright;
    }
    > div:not(#footer-ready-to-get-started-row) {
      &#footer-other-rows {
        padding: 44px 20px 48px;
        margin: {
          left: auto;
          right: auto;
        }
      }
      display: grid;
      grid-template-columns: 300px auto;
      grid-template-rows: 300px auto;
      @include media-breakpoint-only(lg) {
        grid-template-columns: 300px auto;
        grid-template-rows: 225px auto min-content auto;
      }
      grid-template-areas:
        "subscribe    mobile-footer-links"
        "badges       badges"
        "social-media social-media"
        "copyright    copyright";
      #mobile-footer-links {
        font-size: 16px;
        line-height: 16px;
        display: grid;
        @include media-breakpoint-only(lg) {
          grid-template-rows: 90px 42px 42px auto;
          grid-template-columns: 170px 170px 170px 1fr;
          grid-template-areas:
            "mobile-customer-service-callout mobile-customer-service-callout mobile-customer-service-callout mobile-customer-service-callout"
            "mobile-my-account-footer        mobile-order-history-footer     mobile-auto-order-footer        mobile-privacy-settings"
            "mobile-reorder-footer           mobile-vendor-footer            mobile-top-sellers-footer       mobile-dont-sell-info-footer"
            "mobile-contact-us-footer        mobile-terms-footer             mobile-privacy-footer           mobile-dont-sell-info-footer";
        }

        @include media-breakpoint-between(sm, lg) {
          grid-template-rows: 90px 42px 42px 42px 42px 42px auto;
          grid-template-columns: 170px 170px 1fr;
          grid-template-areas:
            "mobile-customer-service-callout mobile-customer-service-callout mobile-customer-service-callout"
            "mobile-my-account-footer        mobile-order-history-footer     mobile-order-history-footer"
            "mobile-auto-order-footer        mobile-reorder-footer           mobile-reorder-footer"
            "mobile-vendor-footer            mobile-top-sellers-footer       mobile-top-sellers-footer"
            "mobile-contact-us-footer        mobile-terms-footer             mobile-terms-footer"
            "mobile-privacy-footer           mobile-privacy-settings         mobile-privacy-settings"
            "mobile-dont-sell-info-footer    mobile-dont-sell-info-footer    mobile-dont-sell-info-footer";
        }

        padding-left: 50px;
      }

      #vertical-links,
      #corporate-info,
      #my-account-footer-links,
      // #copyright-info,
      #let-us-help-links,
      #customer-service-callout {
        display: none;
      }

      .mobile-customer-service-callout {
        padding: {
          top: 10px;
        }

        .questions-please-contact {
          padding-bottom: 14px;
          font-weight: 300;
        }

        .mobile-contact-bar {
          * {
            color: #fff;
            font-weight: 400;
          }
        }
      }
      #subscribe-to-email {
        padding: 14px 16px;
        max-height: 225px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 24px 70px 1fr;
        grid-template-areas:
          "title"
          "subtext"
          "form";
        .h5 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 0;
          padding-bottom: 6px;
        }
        #email-subscribe-form-footer {
          margin-bottom: 30px;
        }
        #email-subscribe-form-footer-button-group {
          padding-top: 13px;
        }
      }
      #email-capture-text {
        margin-top: 0.25rem;
      }
      #social-media-links {
        display: flex;
        justify-content: center;
      }
      #trust-badges {
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);

        > div {
          padding-bottom: 32px;
          width: 300px;
          display: flex;
          justify-content: center;
        }

        .bbb-badge {
          padding-bottom: 55px;
          padding-left: 32px;
          justify-content: flex-start;
        }

        div.google-site-reviews-badge {
          padding-bottom: 53px;
        }
      }
    }
  }
}
@include media-breakpoint-only(md) {
  #footer {
    > div:not(#footer-ready-to-get-started-row) {
      #trust-badges {
        .trustpilot-badge {
          padding-bottom: 0;
        }
        div.google-site-reviews-badge {
          padding-bottom: 23px;
        }
        .bbb-badge {
          padding-left: 0 !important;
          justify-content: center !important;
          padding-bottom: 32px;
        }
        .hq-in-nc-badge {
          padding-bottom: 15px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #footer {
    > div {
      #trust-badges {
        .hq-in-nc-badge {
          margin-left: 32px;
          margin-bottom: 26px;

          .hq-in-nc-badge-img {
            width: 294px;
            height: 41px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, lg) {
  #footer {
    #footer-other-rows {
      #copyright-info {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  #footer {
    padding: {
      left: 0;
      right: 0;
      // top: 16px;
      bottom: 34px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    #footer-ready-to-get-started-row {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      line-height: 16px;
      background-color: #f1f6fe;
      padding: 52px 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding: {
        left: 20px;
        right: 20px;
      }
      #footer-ready-to-get-started-banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        h2 {
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          padding: 0 0 4px;
        }
        .get-started-cta {
          margin-left: auto;
          a {
            font-size: 18px;
            line-height: 24px;
            padding: {
              left: 32px;
              right: 32px;
            }
            max-height: 46px;
            white-space: nowrap;
            // padding: 10px 32px;
          }
        }
      }
    }
    #copyright-info {
      border-top: 1px solid $footer-horizontal-divider-color;
      padding: {
        top: 24px;
        left: 16px;
        right: 16px;
      }
      margin-top: 24px;
      grid-area: copyright;
    }
    #footer-other-rows {
      justify-items: center;
      > *:not(#copyright-info):not(#trust-badges) {
        width: 300px;
      }
    }
    > div:not(#footer-ready-to-get-started-row) {
      // width:300px;
      width: 100%;
      display: grid;
      // grid-template-columns: 300px;
      grid-template-columns: 1fr;
      grid-template-rows: 210px auto auto auto auto;
      grid-template-areas:
        "subscribe"
        "mobile-footer-links"
        "badges"
        "social-media"
        "copyright";
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      &#footer-other-rows {
        padding-top: 16px;
        margin: {
          left: auto;
          right: auto;
        }
      }
      > * {
        text-align: left;
      }
      #mobile-footer-links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 70px 40px 40px 40px 40px 40px 40px;
        font-size: 14px;
        line-height: 16px;
        grid-template-areas:
          "mobile-customer-service-callout mobile-customer-service-callout"
          "mobile-my-account-footer        mobile-order-history-footer"
          "mobile-auto-order-footer        mobile-reorder-footer"
          "mobile-vendor-footer            mobile-top-sellers-footer"
          "mobile-contact-us-footer        mobile-terms-footer"
          "mobile-privacy-footer           mobile-dont-sell-info-footer"
          "mobile-privacy-settings         mobile-privacy-settings";
        padding: {
          top: 30px;
          left: 10px;
          right: 10px;
        }
      }
      #vertical-links,
            #corporate-info,
            #my-account-footer-links,
            // #copyright-info,
            #let-us-help-links,
            #customer-service-callout {
        display: none;
      }
      .mobile-customer-service-callout {
        padding: {
          bottom: 20px;
        }
        .questions-please-contact {
          padding-bottom: 10px;
          font-weight: 300;
          white-space: nowrap;
        }
        .mobile-contact-bar {
          * {
            color: #fff;
            font-weight: 400;
          }
        }
      }
      #subscribe-to-email {
        padding: 14px 16px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 24px 70px 1fr;
        grid-template-areas:
          "title"
          "subtext"
          "form";
        .h5 {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 0;
          padding-bottom: 6px;
        }
        #email-subscribe-form-footer-button-group {
          padding-top: 13px;
        }
      }
      #social-media-links {
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }
      #trust-badges {
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        flex-direction: column;
        width: 100%;

        .trustpilot-badge {
          padding-bottom: 0;
          padding-left: 13px;
        }

        div.google-site-reviews-badge {
          padding-bottom: 32px;
        }

        .bbb-badge {
          padding-bottom: 16px;
        }

        .hq-in-nc-badge {
          margin-top: 16px;
          margin-bottom: 16px;

          .hq-in-nc-badge-img {
            width: 219px;
            height: 30.75px;
          }
        }
      }
    }
  }
}
