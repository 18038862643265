@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.shoppingListContainer {
  display: flex;
  margin-bottom: 18px;
  margin-top: 10px;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }

  .shoppingListLink {
    color: #4385f6;
    cursor: pointer;
    padding: 0;
  }

  .saveForLater {
    margin-right: 10px;
  }
}
