@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

// This file is a fork of the product weekly-specials-products file

$mobile-weekly-specials-products-image-width: 90px;

.weeklySpecialsProductContainer {
  outline: none !important;
}
.weeklySpecialsProduct {
  display: grid;
  outline: none;
  padding: 10px;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }
  > a {
    display: block !important;
  }
  .weeklySpecialsProductImage {
    grid-area: weekly-specials-product-image;
    display: flex !important;
    justify-content: center;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      margin: 0;
      padding: 0;
    }
  }

  .weeklySpecialsProductBrand {
    grid-area: weekly-specials-product-brand;
    &:hover {
      text-decoration: underline !important;
      color: theme-color("primary") !important;
    }
  }

  .weeklySpecialsProductTitle {
    grid-area: weekly-specials-product-title;
    &:hover {
      text-decoration: underline !important;
      color: theme-color("primary") !important;
    }
  }
  .weeklySpecialsProductPrice,
  .weeklySpecialsProductRating,
  .weeklySpecialsProductSavings {
    &:hover {
      text-decoration: none !important;
    }
  }
  .weeklySpecialsProductPrice {
    grid-area: weekly-specials-product-price;
    color: $secondary !important;
    font-size: 15px;
    font-weight: 500;
  }
  .weeklySpecialsProductRating {
    $width: 75px;
    grid-area: weekly-specials-product-rating;
    // height: 15px;
    width: $width;
    background-image: url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg),
      url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg),
      url(/media/shared/common/reviews/grey_star.svg);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 0, 15px, 30px, 45px, 60px;
    background-size: 15px;
    position: relative;
    padding-right: 25px;
    &.recommendatingRating-0_0 {
      visibility: hidden;
    }
    .weeklySpecialsProductRatingsCount {
      position: absolute;
      left: $width;
      // width:20px;
      // max-width:20px;
      padding-left: 5px;
      color: #dddddd;
      font-size: 15px;
      white-space: nowrap;
    }
    .weeklySpecialsProductRatingsInnerTrack {
      background-image: url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg);
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
      background-position: 0, 15px, 30px, 45px, 60px;
      background-size: 15px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      &.recommendatingRating-0_0 {
        width: 0;
      }
      &.recommendatingRating-0_5 {
        width: 10%;
      }
      &.recommendatingRating-1_0 {
        width: 20%;
      }
      &.recommendatingRating-1_5 {
        width: 30%;
      }
      &.recommendatingRating-2_0 {
        width: 40%;
      }
      &.recommendatingRating-2_5 {
        width: 50%;
      }
      &.recommendatingRating-3_0 {
        width: 60%;
      }
      &.recommendatingRating-3_5 {
        width: 70%;
      }
      &.recommendatingRating-4_0 {
        width: 80%;
      }
      &.recommendatingRating-4_5 {
        width: 90%;
      }
      &.recommendatingRating-5_0 {
        width: 100%;
      }
    }
  }
  .weeklySpecialsProductSavings {
    grid-area: weekly-specials-product-savings;
    .strike {
      text-decoration: line-through;
      color: #565656;
      font-weight: 300;
    }
    .savings {
      font-weight: 500;
      color: $savings-color;
    }
  }
  .weekly-specials-product-add-to-cart-container {
    grid-area: weekly-specials-products-add-to-cart;
    display: flex;
    align-items: flex-end;
    height: 99%;
    .btn {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .weekly-specials-product-add-to-cart-button {
      width: 100%;
    }
  }
  .percentageOff {
    position: absolute;
    background-color: $homepage-weekly-specials-savings-color;
    top: 10px;
    left: 7px;
    color: #fff;
    border-radius: 10px;
    font-size: 10px;
    padding: 4px 10px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 5fr;
    grid-template-rows:
      140px
      auto
      auto
      20px
      30px
      1fr;
    grid-template-areas:
      "weekly-specials-product-image        weekly-specials-product-image"
      "weekly-specials-product-brand        weekly-specials-product-brand"
      "weekly-specials-product-title        weekly-specials-product-title"
      "weekly-specials-product-price        weekly-specials-product-rating"
      "weekly-specials-product-savings      weekly-specials-product-savings"
      "weekly-specials-products-add-to-cart weekly-specials-products-add-to-cart";
    border-right: 1px solid #eeeeee;
    .weeklySpecialsProductImage {
      justify-content: center;
      align-items: center;
      img {
        max-width: 140px;
        max-height: 140px;
      }
    }

    .weeklySpecialsProductBrand {
      line-height: 18px;
      max-height: 54px;
      min-height: 18px;
      overflow: hidden;

      font-weight: 500;
      line-height: 18px;
      color: #000000;
    }

    .weeklySpecialsProductTitle {
      line-height: 18px;
      max-height: 54px;
      min-height: 18px;
      overflow: hidden;
    }
    .weeklySpecialsProductPrice {
      padding-right: 0.5rem;
    }
  }

  @include media-breakpoint-between(xs, sm) {
    grid-template-columns: $mobile-weekly-specials-products-image-width auto 1fr;
    grid-template-rows:
      auto
      auto
      24px
      30px
      auto;
    grid-template-areas:
      "weekly-specials-product-image weekly-specials-product-brand        weekly-specials-product-brand"
      "weekly-specials-product-image weekly-specials-product-title        weekly-specials-product-title"
      "weekly-specials-product-image weekly-specials-product-rating       weekly-specials-product-rating"
      "weekly-specials-product-image weekly-specials-product-savings      weekly-specials-product-price"
      ".                    weekly-specials-products-add-to-cart weekly-specials-products-add-to-cart";
    padding: 10px;
    border-bottom: 1px solid #eeeeee;

    .weeklySpecialsProductBrand {
      font-size: 13px;
      max-height: 32px;
      min-height: 16px;
      overflow: hidden;

      font-weight: 500;
      line-height: 18px;
      color: #000000;
    }

    .weeklySpecialsProductTitle {
      font-size: 13px;
      line-height: 16px;
      max-height: 32px;
      min-height: 16px;
      overflow: hidden;
    }
    .weeklySpecialsProductSavings {
      padding-right: 0.5rem;
      font-size: 11px;
      font-weight: 300;
    }
    .weeklySpecialsProductPrice {
      font-size: 17px;
    }
    .weeklySpecialsProductImage {
      justify-content: flex-start;
      align-items: flex-start;
      img {
        max-width: 70px;
        max-height: 70px;
      }
    }
    .weeklySpecialsProductSavings .savings {
      display: none;
    }
  }

  .weekly-specials-product-add-to-cart-button {
    display: none;
  }
}
