@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// @import "theme/ec/themeVars";

@import "styles/themeVars";

#my-account-license-page {
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 525px;
  }

  @include media-breakpoint-up(md) {
    .checkout-submit-button {
      margin-left: -10px;
    }
  }

  .my-account-license-page-container #my-account-page {
    min-width: unset !important;
    max-width: 100px !important;
  }

  .container {
    border-bottom: 1px solid #ddd;

    &:first-of-type {
      border-top: 1px solid #ddd;
    }
  }

  .license-form {
    .btn-outline-primary {
      &:hover {
        color: white;
      }
    }

    .btn-primary {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  // Band-aid fix for the date fields incorrectly applying style when they become invalid
  // Need to find what is actually wrong with the site form stylesheet.
  select.form-control {
    padding-right: 18px;

    &.is-invalid {
      border-color: $danger;
    }
  }

  @include media-breakpoint-down(lg) {
    .checkout-page {
      display: none;
    }
  }
}
