@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.toastContainer {
  position: fixed;
  left: 50vw;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px; /* adjust width as needed */
  z-index: 3;

  span {
    color: #333; /* adjust color as needed */
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &.success span::before {
    content: "✓";
    color: green; /* adjust color as needed */
    margin-right: 10px;
    font-weight: bold;
  }

  Link {
    color: $vertical-color-50; /* adjust color as needed */
    text-decoration: underline;
    font-weight: bold;
  }

  &.error span::before {
    content: "✗";
    color: red; /* adjust color as needed */
  }

  .toastLink {
    padding-top: 5px;
  }
}

.topToast {
  top: 120px;
  bottom: auto;
}

.bottomToast {
  bottom: 120px;
  top: auto;

  @include media-breakpoint-down(sm) {
    top: 35%;
    bottom: auto;
  }
}
