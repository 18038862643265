@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "styles/themeVars";

#user-verification {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 14px;

  margin-bottom: 20px;

  .font-weight-bold {
    font-weight: $font-weight-bold;
  }

  .padding-left {
    padding-left: 5px;
  }

  .submit-button {
    .btn-outline-primary {
      &:hover {
        color: white;
      }
    }
  }
  .deactivateBtnContainer {
    justify-content: flex-end;
    .deactivateAccountBtn {
      width: 200px;
      margin-right: 10px;
    }
  }

  .errorTextContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 5px;
  }

  .warningIcon {
    margin-bottom: 3px;
    margin-right: 5px;
  }
}
