@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#server-in-not-found-state {
  width: 100%;
  max-width: 1340px;
  margin: auto;
  section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 44px;
    padding-bottom: 44px;

    .oops-image img {
      width: 100%;
      height: auto;
      max-width: 480.69px;
      max-height: 250px;
      min-height: 110px;
      min-width: 211px;

      @include media-breakpoint-up(sm) {
        height: 250px;
      }

      // @include media-breakpoint-up(lg) {
      //     height: 250px;
      // }
      // @include media-breakpoint-up(xl) {
      //     height: ;
      // }
    }
    h1 {
      @include robotoBlack();
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      padding-top: 32px;
      padding-bottom: 16px;
      margin: 0;
    }
    p {
      max-width: 715px;
      @include robotoBlack();
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0;
      padding-bottom: 16px;
      strong,
      a {
        white-space: nowrap;
      }
    }
    @include media-breakpoint-down(lg) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  .phoneNumber {
    color: black;
  }
}
