@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.shipping-address-picker {
  margin-top: 1em;
  background-color: $blur-white;

  .shipping-address-color {
    color: $early_grey;
  }
  .shipping-address-name {
    color: $black-color;
    @include robotoBlack;
    font-weight: 500;
    padding-left: 8px;
  }
  .shipping-address {
    & > * {
      padding-left: 8px;
    }
  }
  .shipping-address-controls {
    grid-area: shipping-address-controls;
  }

  .shipping-address-controls {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    & > * {
      padding-left: 8px;
    }
    padding-left: 1.5em;
  }

  .shipping-address-radio {
    cursor: pointer;
    order: 1;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    border: 0;
  }

  .shipping-address-divider {
    height: 1px;
    width: 100%;
    background-color: $light-grey;
  }
}

.address-deletion-buttons-container {
  display: flex;
  gap: 10px;
}

.shipping-address-picker > div > input {
  cursor: pointer;
  height: 21px;
  width: 21px;
}
