@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.recommendationsSlider {
  border-top: 1px solid #eee;
  @include media-breakpoint-up(md) {
    box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.1);
    border-top: 0;
  }

  &.mobile :global(.swiper-wrapper) {
    height: auto !important; // As of writing, swiper tries to set the height to 0, this forces it not to do that.
  }
}

.maxGridWith {
  max-width: 1236px;
}
.swiperArrow {
  position: relative;
  height: 40px;
  width: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: calc(50% - 20px);
  z-index: 2;
  &:before {
    content: "";
    display: block;
    border-top: 2px solid #989898;
    border-left: 2px solid #989898;
    width: 18px;
    height: 18px;
  }
  &:global(.swiper-button-disabled) {
    opacity: 0.5;
    cursor: default;
  }

  &.prev {
    left: 0;
    &:before {
      transform: rotate(-45deg);
    }
  }
  &.next {
    right: 0;
    &:before {
      transform: rotate(135deg);
    }
  }
}

.pagination {
  height: 38px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  :global {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
    }
  }
}
