@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.searchPage {
  background-color: $lightest-grey;
  @include media-breakpoint-down(md) {
    background-color: #fff;
  }

  .ais-Hits-item {
    width: 400px;
  }

  .customCard {
    background-color: white;
    @media screen and (min-width: 992px) {
      margin: 0.7rem;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
  }

  .currentRefinements {
    font-size: 12px;
    display: block;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    background-color: #fff;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .resultsContainer {
    padding: 0.5rem 0;
    background-color: white;
    border-bottom: 1px solid lightgray;
    @media screen and (min-width: 992px) {
      border-bottom: none;
      padding: 1em 0px 0px 0px;
      background-color: #f8f8f8;
    }
  }
}

.desktopFilters {
  display: none;
}

li.listView {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  & > div {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}

li.bannerItem {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  @media screen and (min-width: 880px) {
    width: 66.6%;
    max-height: 477px;
  }
  // @media screen and (min-width: 992px) {
  //   max-height: 477px;
  // }
  @media screen and (min-width: 1200px) {
    width: 50%;
  }
  @media (min-width: 1980px) {
    width: 25%;
  }
  @media screen and (min-width: 768px) {
    border: none;
    max-height: 477px;
  }
  &.listView {
    padding-left: 12px !important;
    padding-right: 12px !important;
    & > a {
      border-bottom: none;
      @media screen and (min-width: 992px) {
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: none;
        background: white;
      }
    }
  }
}

.productCard {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 50%;
    border-bottom: none;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 880px) {
    width: 33.33%;
  }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 25%;
    &.listView {
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    &.listView {
      width: 100%;
    }
  }

  // XX-Large devices (larger desktops, 1400px and up)
  // @media (min-width: 1400px) {
  //   width: 20%;
  // }
}

@include media-breakpoint-up(lg) {
  .desktopFilters {
    display: block;
  }
}
