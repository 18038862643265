@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.review-about-you {
  margin-bottom: 20px;

  h3 {
    font-size: 1rem;
    font-weight: 500;

    margin-bottom: 10px;
  }

  .success-message {
    color: #469850;

    margin-bottom: 10px;
    font-weight: 500;
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* Optional: space between boxes */
    max-width: 950px;
  }

  .box {
    flex: 1 1 calc(33.33% - 16px); /* 3 boxes per row */
    box-sizing: border-box;

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }

    button {
      margin-top: 28px;
      width: 100px;
    }
  }

  .other-occupation {
    .box {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        display: block;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .box {
      flex: 1 1 calc(33.33% - 16px); /* 3 boxes per row */
    }
  }

  @include media-breakpoint-down(sm) {
    .box {
      flex: 1 1 100%; /* Full width (one per row) */

      button {
        margin-top: 0;
        width: 100%;
      }
    }

    .other-occupation {
      .box {
        &:nth-child(4),
        &:nth-child(6) {
          // background-color: purple;

          display: none;
        }

        &:nth-child(3) {
          order: 5; /* Move box 3 to the position of box 5 */
        }

        &:nth-child(5) {
          order: 3; /* Move box 5 into the position of box 4 */
        }
      }
    }
  }
}
