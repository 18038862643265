@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "styles/themeVars";

#billing-form-v2 {
  width: 100%;
  .cc-number-wrapper {
    padding-right: 35px;
    background-position: right 7px center;
    background-repeat: no-repeat;
    background-size: auto !important;
    &.card-type-visa {
      background-image: url(/media/shared/common/icons/visa-25x16.png);
    }
    &.card-type-mastercard {
      background-image: url(/media/shared/common/icons/mastercard-25x16.png);
    }
    &.card-type-amex {
      background-image: url(/media/shared/common/icons/amex-25x16.png);
    }
    &.card-type-discover {
      background-image: url(/media/shared/common/icons/discover-25x16.png);
    }
  }
  .cc-cvc-wrapper {
    padding-right: 54px;
    position: relative;
    &:after {
      content: "";
      display: block;
      background-image: url(/media/shared/common/credit-card/security-code-image.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 41px;
      height: 28px;
      position: absolute;
      right: 7px;
      top: calc(50% - 14px);
    }
  }
  @include media-breakpoint-up(md) {
    max-width: 450px;
    #cvc-number-text {
      display: block;
      white-space: nowrap;
      overflow: visible;
      float: right;
      padding-top: 4px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: auto !important;
  }

  .form-control-invalid {
    border-color: #fd4040;
  }
}

#checkout-page-logic {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}
