@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.order-update-title {
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.error-message {
  display: block;
}

.send-invitations {
  margin-right: 15px;
}

.footer-modal {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  button {
    padding: 0px;
  }
}
