@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.suggestionPanelFooter {
  color: $vertical-color-50;
  border-top: 1px solid lightgray;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.sectionHeader {
  font-size: 0.813rem;
  line-height: 1.5;
}

.searchForm {
  position: relative;
  @media screen and (min-width: 512px) {
    position: unset;
  }
}

.searchContainer {
  @media screen and (min-width: 512px) {
    position: relative;
  }
}

.suggestedProduct {
  color: $black-color;
  &:hover .title {
    color: $primary !important;
    text-decoration: underline !important;
  }
  .image {
  }
  .title {
    color: $black-color;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0em;
    max-height: 42px;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .price span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
}
