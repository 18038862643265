@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#reorderSortPopover {
  width: 200px;

  .sortPopoverBody {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;
        font-size: 14px;
        padding: 0 10px;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid #e4e4e4;
        }

        &:hover {
          background-color: $vertical-color-50;
          color: #ffffff;
        }
      }

      .selectedSort {
        background-color: $vertical-color-50;
        color: #ffffff;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.reorderSort {
  margin-left: 25px;

  .reorderSortHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #353c43;
      border-bottom: 0;
    }
    .sortText {
      margin-right: 4px;
      font-size: 14px;
      cursor: pointer;
      content: "Sort By:";
      display: none;
    }
    .sortTextColon {
      margin-right: 24px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .mobileSortMenu {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    .reorderSortHeader .sortSelection {
      display: none;
    }

    .sortText {
      display: unset !important;
      padding-right: 4px;
      font-weight: 400 !important;
      content: "Sort by";
    }
    .sortTextColon {
      display: none;
    }

    .mobileSortMenu {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: -105vw;
      height: 100%;
      background: white;
      z-index: 2000;
      width: 100vw;

      transition: left 0.25s 0s ease-out;
      overflow-y: auto;

      &.mobileMenuVisible {
        left: 0;
      }

      .mobileSortByHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 56px;
        line-height: 16px;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 1px solid #e4e4e4;
        padding: 0 16px;
        position: sticky;
        top: 0;
        background: white;
        z-index: 3;

        &:before {
          content: "";
        }

        .mobileSortClose {
          width: 24px;
          height: 24px;
          position: relative;
          transform: scale(0.75);
          cursor: pointer;
          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 24px;
            height: 3px;
            background: #505050;
            top: 11px;
          }
          &:before {
            left: 0px;
            transform: rotate(45deg);
          }
          &:after {
            right: 0px;
            transform: rotate(-45deg);
          }
        }
      }

      .mobileSortList {
        padding: 0;
        margin: 0;

        color: #353c43;

        li {
          border-bottom: 1px solid #e4e4e4;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 36px;
          font-size: 14px;
          padding: 0 10px;
          cursor: pointer;

          &.mobileSortListOptionSelected {
            background-color: #5086fa;
            color: #fff;
          }
        }
      }
    }
  }
}
