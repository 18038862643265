@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.reorderPage {
  display: grid;

  .reorderHeader {
    grid-area: reorder-header;
  }

  .reorderBreadcrumb {
    border-bottom: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;
    padding: 8px 24px;
  }
  .breadcrumbArrow {
    padding-left: 5px;
    padding-right: 5px;
  }
  .reorderHeaderText {
    display: block;
    font-weight: 400;
    font-size: 36px;
    padding: 8px 24px;
    border-right: 1px solid #e4e4e4;
  }

  .reorderFooter {
    grid-area: reorder-footer;
  }

  .reorderBody {
    grid-area: reorder-body;

    .filterSortContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      padding: 14px 24px;

      .filterContainer {
        display: flex;
        flex-direction: row;
      }

      .sortContainer {
        display: flex;
      }
    }

    .selectedCategoriesContainer {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      padding: 5px 24px;

      .categoryTagContainer {
        display: flex;
        flex-direction: row;
      }
      .clearCategories {
        cursor: pointer;
        color: #4385f6;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .shoppingCartContainer {
    grid-area: shopping-cart;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: auto 320px;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      "reorder-header shopping-cart"
      "reorder-body shopping-cart"
      "reorder-footer shopping-cart";
  }

  @include media-breakpoint-between(md, lg) {
    .shoppingCartContainer {
      display: none;
    }

    .reorderBreadcrumb {
      display: none;
    }

    .reorderHeaderText {
      font-size: 24px;
      padding: 8px 16px;
    }

    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "reorder-header"
      "reorder-body"
      "reorder-footer";
  }

  @include media-breakpoint-down(md) {
    .shoppingCartContainer {
      display: none;
    }
    .reorderBreadcrumb {
      display: none;
    }

    .reorderHeaderText {
      font-size: 24px;
      padding: 8px 16px;
    }

    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "reorder-header"
      "reorder-body"
      "reorder-footer";
  }
}
