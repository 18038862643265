@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// @import "theme/ec/themeVars";

@import "styles/themeVars";

.account-manager-layout-container {
  margin-left: 18px;
  margin-right: 18px;
  padding: 20px 10px;

  .invalid-feedback.invalid-feedback-override {
    margin-top: -8px;
    margin-bottom: -10px;
    display: block;
  }
  .alert.alert-override {
    width: 100%;
  }
  .form-control.is-invalid {
    background-image: none;
    &:hover {
      border-color: $action-color;
    }
  }

  dl {
    // display: flex;
    // flex-flow: row wrap;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
  }
  dt {
    display: inline-block;
    font-weight: bold;
    width: auto;
    // text-transform: uppercase;
    flex-basis: 12%;
    width: 12%;
    padding: 2px 4px;
    text-align: right;
    white-space: nowrap;

    &:last-of-type {
      width: 0;
    }
  }
  dd {
    display: inline-block;
    flex-basis: 88%;
    width: 88%;
    flex-grow: 1;
    align-self: end;
    margin: 0;
    padding: 2px 4px;
    a {
      padding-left: 7px;
      padding-right: 7px;
    }

    &:last-of-type {
      width: 100%;
    }
  }
  dd::after {
    content: "";
    display: block;
    clear: both;
  }

  #account-manager-layout-page {
    // max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    .page-header {
      display: flex;
      align-items: center;
      // padding-bottom: 25px;
      .account-main-link {
        margin-left: auto;
      }

      @include media-breakpoint-only(xs) {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 5px;
        .account-main-link {
          margin-left: 0;
        }
      }
    }
    .account-manager-content {
      display: flex;
      flex-direction: column;
      .account-manager-sections {
        display: flex;
        flex-wrap: wrap;
        .my-account-standard-form {
          width: 100%;
          max-width: 450px;
        }
        #name-prefix {
          min-height: 42px;
          min-width: 80px;
        }
        .form-group {
          .dropdown {
            button {
              font-weight: 400;
              padding-left: 10px;
              padding-right: 10px;
            }
            .dropdown-menu {
              min-width: 0;
              max-height: 330px;
              .dropdown-item {
                padding: 0.25rem 1.5rem;
              }
            }
          }
        }
        .card {
          // background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#fff));
          border-radius: 8px;
          flex-basis: 100%;
          margin: 8px 0;
          min-width: 206px;
          .card-body {
            padding: 16px;
            button {
              white-space: nowrap;
              margin-left: auto;
              margin-right: auto;
            }
            .card-text {
              display: flex;
              flex-direction: column;
              > button {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.delete-cc-email {
  padding: 10px;
  .email-address {
    font-weight: 500;
  }
  div:first-of-type {
    display: flex;
    flex-direction: column;
  }
  div:last-of-type {
    display: flex;
    flex-direction: row;
  }
  button {
    margin-top: 10px;
    margin-right: 10px;
  }
}

// Tablet
@include media-breakpoint-between(md, lg) {
  .account-manager-layout-container {
    button:first-of-type {
      margin-right: 10px;
    }

    #account-manager-layout-page {
      .account-manager-content {
        .account-manager-sections {
          dt {
            width: 17%;
            flex-basis: 17%;
          }
          dd {
            width: 83%;
            flex-basis: 83%;
          }
        }
      }
    }
  }
}

// Small tablet, or a tablet in portrait orientation.
@include media-breakpoint-between(sm, md) {
  .account-manager-layout-container {
    button:first-of-type {
      margin-right: 10px;
    }

    #account-manager-layout-page {
      .account-manager-content {
        .account-manager-sections {
          dt {
            width: 25%;
            flex-basis: 25%;
          }
          dd {
            width: 75%;
            flex-basis: 75%;
          }
        }
      }
    }
  }
}

// Phone
@include media-breakpoint-down(sm) {
  .account-manager-layout-container {
    button:first-of-type {
      margin-right: 10px;
    }
    #account-manager-layout-page {
      .account-manager-content {
        // .account-manager-layout-sections
        .account-manager-sections {
          dt {
            padding-top: 10px;
            text-align: left;
          }
          dt,
          dd {
            width: 100%;
            flex-basis: 100%;
          }
          .card {
            flex-basis: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
