@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#takedown-modal-contents {
  margin: 0;
  :global {
    .lg {
      font-size: 16px;
    }
  }
  .takedown-button-group {
    padding-top: 16px;
    :global {
      .btn {
        margin-right: 10px;
      }
    }
  }
}
