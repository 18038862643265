@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";
.shipping-form {
  .link-add-company {
    margin-top: -5px;
    margin-bottom: 5px;
    padding-left: 12px;
  }
  .text-muted-required {
    // color: #a1a1a1 !important;
  }
  .form-control {
    height: 42px;
  }
}
