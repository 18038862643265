@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#reorderCard,
#need-help-getting-started {
  background-color: #fff;
  border-radius: 4px;

  .cardItemWithImage {
    .icon {
      width: 35px;

      img {
        display: block;
        width: 32px;
        height: auto;
        padding-right: 10px;
        padding-top: 4px;
      }

      .circleBadge {
        background-color: $action-color;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 14px;
        line-height: 14px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: white;
      }
    }
  }

  .weeklySpecials {
    padding-left: 24px;
    padding-top: 12px;
  }

  .viewCategories {
    border-top: 1px solid #ededed;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@include media-breakpoint-up(xl) {
  #reorderCard,
  #need-help-getting-started {
    margin-left: 24px;
    flex: 0 1 300px;
  }
}
