@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#server-offline-or-in-error-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  div {
    background-image: url(/media/shared/common/backgrounds/system_error_background.jpg);
    background-repeat: no-repeat;
    height: 462px;
    min-height: 462px;
    width: 970px;
    padding-left: 40px;
    h1 {
      font-size: 50px;
      font-family: Georgia, serif;
      font-weight: 400;
      color: #eee;
      padding-top: 110px;
    }
    span {
      font-size: 22px;
      line-height: 27px;
      color: #eee;
      padding-top: 20px;
    }
  }
}
