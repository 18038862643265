@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "styles/themeVars";

.modal-backdrop.address-suggestion-popup,
.modal.address-suggestion-popup {
  z-index: 10000000000;
}
.address-suggestion-popup {
  .modal-dialog {
    .modal-content {
      .shipping-address-divider {
        height: 1px;
        width: 100%;
        background-color: $light-grey;
        margin: 24px 0;
      }
      .modal-body {
        padding: 0 24px;
        h3 {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          margin: 0 0 11px;
        }
        h4 {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin: 0 0 11px;
        }
        .form-check {
          input {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
        .suggested-addresses {
          .form-check {
            line-height: 21px;
            margin: 8px 0;
            label {
              padding-left: 8px;
              white-space: pre-wrap;
            }
            &:first-of-type {
              label {
                color: rgb(0, 204, 153);
              }
            }
          }
        }
        .suggestion-entered-address {
          white-space: pre-wrap;
          .form-check {
            display: inline-block;
            label {
              padding-left: 8px;
              white-space: pre-wrap;
            }
          }
          a {
            padding-left: 18px;
          }
        }
        .no-suggestions-statement {
          padding-top: 20px;
        }
      }
      .modal-footer {
        padding: 24px;
        padding-top: 0px;
        justify-content: flex-start;
        button {
          display: flex;
          align-items: center;
          padding: 7px 24px;
          border-radius: 18px;
          // color: white;
          // background-color: #4385f6;
          // border-color: #4385f6;
          &.suggestions-shown {
            margin-top: 40px;
          }
          &:disabled {
            cursor: not-allowed;
            opacity: 1;
            background-color: $vertical-color-70;
            border-color: $vertical-color-70;
          }
        }
      }
    }
  }
}
