@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#vendor-detail-page {
  max-width: 1320px;
  width: 100%;
  margin: auto;
  padding: {
    bottom: 2rem;
  }
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows:
    min-content
    min-content
    minmax(560px, min-content)
    min-content
    min-content;
  grid-template-areas:
    "breadcrumbs"
    "title"
    "details"
    "reviews"
    "recommendations"
    "products";
  $gapSize: 1rem;
  gap: $gapSize;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 425px;
    grid-template-rows:
      min-content
      min-content
      minmax(560px, min-content)
      min-content
      min-content;
    grid-template-areas:
      "breadcrumbs breadcrumbs"
      "title title"
      "details reviews"
      "recommendations recommendations"
      "products products";
  }
  @include media-breakpoint-up(md) {
    padding: {
      left: 10px;
      right: 10px;
    }
  }
  #vendor-breadcrumbs {
    grid-area: breadcrumbs;
    padding-top: 0.5rem;
  }
  #vendor-title-and-ratings {
    grid-area: title;
    h1 {
      font-size: 32px;
      min-height: 38px;
    }
    padding-bottom: $gapSize;
    border-bottom: 1px solid #e4e4e4;
  }
  #vendor-details {
    grid-area: details;
    .section-header {
      font-weight: 500;
      font-size: 1rem;
    }
    p {
      font-weight: 400;
    }
    .authorized-dealer-badge {
      padding-bottom: 0.5rem;
      &:last-of-type {
        padding-bottom: 0;
      }
      img {
        height: 22px;
        width: auto;
      }
    }
  }
  #vendor-reviews {
    grid-area: reviews;
    padding-left: $gapSize;
    border-left: 1px solid #e4e4e4;
    #detailed-ratings {
      margin-bottom: $gapSize * 2;
      table {
        th {
          font-weight: 500;
        }
        th:first-of-type,
        td:first-of-type {
          min-width: 165px;
          padding-right: 0.5rem;
        }
        th:nth-of-type(2),
        td:nth-of-type(2) {
          min-width: 120px;
          padding-right: 0.5rem;
        }
        th:last-of-type,
        td:last-of-type {
          text-align: center;
        }
      }
    }
    #ratings-list {
      ul {
        max-height: 500px;
        overflow-y: auto;
        li {
          border-bottom: 1px solid #e4e4e4;
          p {
            font-weight: 400;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
          &:last-of-type {
            border: none;
          }
        }
      }
    }
    #detailed-ratings,
    #ratings-list {
      > strong {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    #vendor-title-and-ratings,
    #vendor-details,
    #vendor-breadcrumbs,
    #vendor-reviews {
      padding: {
        left: 10px;
        right: 10px;
      }
    }
    #vendor-reviews {
      border-left: 0;
    }
  }
  @include media-breakpoint-only(md) {
    #vendor-reviews {
      display: flex;
      gap: $gapSize;
      position: relative;
      #detailed-ratings {
        flex-basis: 420px;
        min-width: 420px;
        position: sticky;
        top: 0;
      }
      #ratings-list {
        flex-grow: 1;
      }
    }
  }
  #vendor-recommendations {
    max-width: 100%; // for certona
    grid-area: recommendations;
    .product-recommendations-padding {
      margin-bottom: 2rem;
    }
  }
  .vendor-products {
    grid-area: products;
  }
}
