@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.tagContainer {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    color: #5086fa;
    .removeTag {
      background-color: #5086fa;

      &:before,
      &:after {
        border-color: #fff;
      }
    }
  }

  .removeTag {
    margin-top: 6px;
    margin-bottom: 5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    margin-right: 3px;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 11px;
      position: absolute;
      top: 7px;
      border-style: solid;
      border-width: 0.5px;
      border-color: #5086fa;
    }

    &:before {
      transform: rotate(45deg);
      left: 2px;
    }

    &:after {
      transform: rotate(-45deg);
      right: 2px;
    }
  }
  // .removeTag {
  //     height:15px;
  //     width: 15px;
  //     border-radius:50%;
  //     background-color:#FFF;
  //     position: relative;
  //     margin-right: 5px;

  //     &:before, &:after {
  //         content:"";
  //         display:block;
  //         height:1px;
  //         width:11px;
  //         position:absolute;
  //         top:7px;
  //     }
  //     &:before {
  //         transform: rotate(45deg);
  //         left:2px;
  //     }
  //     &:after {
  //       transform: rotate(-45deg);
  //       right:2px;
  //     }
  // }

  .tagName {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
