@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.auto-order-message-box-info {
  color: #353c43;
  font-size: 12px;
  line-height: 18px;

  &.touch-visible {
    display: none;
  }

  @media (hover: none) {
    &.mouse-visible {
      display: none;
    }

    &.touch-visible {
      display: inline-block;
    }
  }

  span {
    a:not([href]) {
      color: $vertical-color-50;

      &:hover {
        color: $vertical-color-50;
        text-decoration-line: underline;
      }
    }
  }
}

#auto-order-detail {
  padding: 20px 16px 0px 16px;
  max-width: 350px;

  .tooltip-heading {
    font-size: 14px;
  }

  .tooltip-paragraph {
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 5px;
  }
}

#date-detail {
  width: 25em;

  section {
    .tooltip-heading {
      padding: 20px 16px 0px 16px;
      font-size: 14px;
    }

    ul {
      list-style-type: none;
      font-size: 13px;

      margin: 0;
      padding-left: 20px;
      padding-right: 0;

      padding-top: 10px;
      padding-bottom: 10px;

      li {
        padding: 0;
      }

      border-bottom: 1px solid #e4e4e4;
    }

    .tooltip-paragraph {
      padding: 10px 16px 16px 16px;
      font-size: 13px;
      line-height: 16px;
      margin: 0;
    }
  }
}

.auto-order-info-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .tooltip-heading {
          font-size: 14px;
        }
      }
    }
  }
}

.auto-order-date-info-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        margin: 0;
        padding: 0;

        section {
          padding: 20px;

          .tooltip-heading {
            font-size: 16px;
          }

          .auto-order-date-list {
            list-style-type: none;
            padding-inline-start: 0px;

            li {
              position: relative;
              margin-left: 24px;
              margin-right: 24px;
              padding-left: 29px;
              font-size: 16px;
              line-height: 62px;
              vertical-align: middle;

              .fast-shipping-icon {
                display: inline-block;
                position: absolute;
                left: -13px;
                top: 13px;
                background-image: url(/media/shared/common/icons/icon-fast-shipping.svg);
                width: 24px;
                min-width: 24px;
                height: 36px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: white;
              }

              &:first-child:before {
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0;
                height: 50%;
                border-left: 1px solid #dcdcdc;
              }

              &:nth-child(2),
              li:nth-child(3) {
                border-left: 1px solid #dcdcdc;
              }

              &:last-child:before {
                content: "";
                position: absolute;
                left: 0;
                height: 50%;
                border-left: 1px solid #dcdcdc;
              }
            }
          }

          &:last-child {
            padding: 10px;
            border-top: 1px solid #e4e4e4;
          }
        }
      }
    }
  }
}
