@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.ordersCard {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 45px 55px 26px 26px;
  grid-template-areas:
    "order-number order-status"
    "order-date order-status"
    "tracking tracking"
    "req-return req-return";

  .secondCard {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  #ordersCard,
  #need-help-getting-started {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  #ordersCard,
  #need-help-getting-started {
    flex: 0 1 300px;
  }
}

.orderNumber {
  grid-area: order-number;
}

.orderDate {
  grid-area: order-date;
}

.orderStatus {
  grid-area: order-status;
}

.tracking {
  grid-area: tracking;
}

.requestReturn {
  grid-area: req-return;
}
