@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";
$light-text: #a1a1a1;
.sideCart {
  height: 100%;
  outline: 1px solid #e4e4e4;
  .sideCartHeaderPadding {
    padding-left: 1em;
    padding-right: 1em;
  }
  .cartTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1em;
  }
  .cartStatsRow {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    font-size: 14px;
    font-weight: 500;
  }
  .savingsRow {
    color: $savings-color;
  }
  .cartStatsContainer {
    font-size: 14px;
    font-weight: 500;
  }
  .stickySideCartHeaderContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 1;
    border-bottom: 1px solid #e4e4e4;
    .viewCartButtonContainer {
      margin: 1em;
      .viewCartButton {
        display: block;
        width: 100%;
        padding-top: 9px;
        padding-bottom: 9px;
        color: $vertical-color-90;
        background-color: $action-color;
        border-color: $action-color;
        border: 0px;
        font-weight: 400;
        font-size: 18px;
      }
      .viewCartButton::after {
        content: "\00a0\00bb";
      }
    }
  }
  .emptyCartMessageContainer {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-top: 20px;
    .emptyCartMessage {
      color: $light-text;
    }
  }
  .reorderItemsContainer {
    position: sticky !important;
    top: 117px;
    max-height: calc(100vh + 4px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
