@import "styles/themeVars";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.checkout-shipping {
  @include media-breakpoint-down(lg) {
    padding: 0.75em;
  }
}
