@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.categoriesFilter {
  margin-left: 25px;

  .categoriesFilterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #353c43;
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

#categoriesFilterPopover {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  max-width: 320px;
  width: 320px;

  .categoriesPopoverBody {
    .categorySearch {
      display: flex;
      position: relative;
      border-bottom: 1px solid #e4e4e4;

      .categorySearchIcon {
        top: calc(50% - 20px);
        height: 40px;
        width: 27px;

        border: 0px;
        outline: none !important;
        background-color: transparent;
        position: absolute;

        background-position: -126px -106px;
        background-size: 234px 175px;

        background-image: url(/media/dental/common/icons/sprite-header-net32-large.svg);
      }

      .categorySearchInput {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        margin-left: 30px;
        height: 40px;
        width: 100%;
        border: none;
        font-size: 14px;
        outline: 0;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      height: 350px;
      overflow-y: auto;
      white-space: pre-wrap;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: #e7e7e7;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;
        font-size: 14px;
        padding: 0 10px;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid #e4e4e4;
        }
      }

      .categoryCheckbox {
        display: flex;
        overflow: hidden;
        label,
        input {
          cursor: pointer;
          align-self: center;
        }

        input[type="checkbox"] {
          margin-right: 10px;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
