@import "styles/themeVars";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.shipping-form {
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 450px;
  }

  .checkout-shipping-footer {
    margin-top: 1rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
