@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#auto-order-cta-dialog {
  z-index: 151;
  .modal-content {
    min-height: 476px;
  }
  .modal-header {
    .autoOrderHeader {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 10px;
    }
    border-bottom: 1px solid #dcdcdc;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    #ec-product-ratings-summary {
      &.recommendation-rating {
        $width: 75px;
        grid-area: recommendation-rating;
        // height: 15px;
        width: $width;
        background-image: url(/media/shared/common/reviews/grey_star.svg),
          url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg),
          url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg);
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
        background-position: 0, 15px, 30px, 45px, 60px;
        background-size: 15px;
        position: relative;
        padding-right: 25px;
        &.recommendating-rating-0_0 {
          visibility: hidden;
        }
        .recommendation-ratings-count {
          position: absolute;
          font-size: 12px;
          line-height: 1.28;
          left: $width;
          padding-left: 5px;
          color: $early-grey;
          white-space: nowrap;
        }
        .recommendation-ratings-inner-track {
          background-image: url(/media/shared/common/reviews/gold_star.svg),
            url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
            url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg);
          background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
          background-position: 0, 15px, 30px, 45px, 60px;
          background-size: 15px;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          &.recommendating-rating-0_0 {
            width: 0%;
          }
          &.recommendating-rating-0_5 {
            width: 10%;
          }
          &.recommendating-rating-1_0 {
            width: 20%;
          }
          &.recommendating-rating-1_5 {
            width: 30%;
          }
          &.recommendating-rating-2_0 {
            width: 40%;
          }
          &.recommendating-rating-2_5 {
            width: 50%;
          }
          &.recommendating-rating-3_0 {
            width: 60%;
          }
          &.recommendating-rating-3_5 {
            width: 70%;
          }
          &.recommendating-rating-4_0 {
            width: 80%;
          }
          &.recommendating-rating-4_5 {
            width: 90%;
          }
          &.recommendating-rating-5_0 {
            width: 100%;
          }
        }
      }
    }
    #cta-add-to-cart-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;

      .auto-order-quantity-dropdown {
        width: 58px;
      }

      .auto-order-add {
        font-size: 14px;
        font-weight: 500;
        padding-top: 12px;
        padding-bottom: 30px;
      }
    }
    #auto-order-frequency-dropdown {
      width: 100%;
    }
    .dropdown {
      padding-bottom: 12px;
      button {
        width: 100%;
      }
    }
    .product-quantity {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: space-around;
      padding-right: 12px;
      .styled-number-select-border {
        height: 36px;
        width: 65px;
        select {
          background-color: $blur-white;
        }
      }
    }
    #current-best-price-cta {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: $action-color;
    }
    #savings-and-quantity-break-container {
      font-size: 16px;
      line-height: 20px;
      text-decoration: line-through;
    }

    button {
      flex-grow: 1;
      max-height: 36px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    #image-cta-container {
      display: flex;
      padding-top: 8px;
    }
    #ao-image-container {
      padding-left: 13px;
      padding-right: 34px;
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      padding-bottom: 21px;
      border-bottom: 1px solid #dcdcdc;
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    #last-purchase-info {
      margin-top: 21px;
      padding-top: 7px;
      padding-bottom: 5px;
      vertical-align: middle;
      width: 100%;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      background-color: #fcfcfc;
    }
    .last-purchase-line {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
    }
    #ec-product-ratings-summary {
      padding-bottom: 12px;
    }
    #price-and-retail-container {
      padding-top: 12px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: row;
    }
    #price-and-shipping-container,
    #savings-and-quantity-break-container {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding-right: 11px;
    }
    #savings-and-quantity-break-container {
      font-weight: 300;
      padding-right: 0;
    }
    .cta-ao-frequency-add-to-cart-container {
      margin-right: 30px;
    }
    .auto-order-frequency-dropdown-container {
      padding-bottom: 12px;
    }

    #ec-product-call-to-action-container {
      padding-bottom: 20px;
    }
    #ec-product-ratings-summary .star-ratings-container.product-star-ratings-container {
      display: inline-block;
    }
    #ec-product-ratings-summary span {
      color: #a1a1a1;
    }
    .next-auto-orders {
      font-size: 14px;
      line-height: 17px;
      color: #303030;
      padding-bottom: 8px;
    }
    .next-ao-dates {
      font-size: 14px;
      line-height: 20px;
      color: #303030;
      padding-bottom: 9px;
    }
    .next-ao-dates span {
      padding-right: 40px;
    }
    .reminder-message {
      font-size: 12px;
      line-height: 17px;
      color: #303030;
      opacity: 0.6;
      padding-bottom: 24px;
    }
  }
}

@include media-breakpoint-down(lg) {
  #auto-order-cta-dialog {
    z-index: 151;

    .modal-content {
      min-height: 476px;
    }
  }

  #image-cta-container {
    flex-direction: row;
    padding-top: 24px;
  }
}

@include media-breakpoint-down(sm) {
  #image-cta-container {
    flex-direction: column;
    padding-top: 18px;
    padding-bottom: 8px;
    align-items: center;
  }

  #auto-order-cta-dialog {
    .modal-content {
      min-height: 553px;
    }

    .modal-body {
      padding: 0 9px 0 16px;

      h1 {
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        padding-bottom: 19px;
        border-bottom: 1px solid #dcdcdc;
        margin-left: -16px;
        padding-left: 16px;
        margin-right: -9px;
        padding-right: 9px;
      }

      h2 {
        line-height: 23px;
        padding-bottom: 8px;
      }

      #ec-product-ratings-summary {
        padding-bottom: 14px;
      }

      #price-and-shipping-container,
      #savings-and-quantity-break-container {
        font-size: 14px;
        line-height: 16px;
      }

      .cta-ao-frequency-add-to-cart-container {
        margin-right: 0;
      }
    }
  }
}
