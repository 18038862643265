@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";
#popover-error {
  border-color: #dd3c10;
  background-color: #ffebe8;

  .popover-body {
    display: flex;
    flex-direction: row;

    .popover-error-body {
      padding: 5px;
    }

    .popover-error-close {
      div {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      div:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);

        right: 3px;
        top: 9px;

        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background: #aaa;
        border-radius: 1px;
      }

      div:after {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(35deg);
        transform: rotate(135deg);

        right: 3px;
        top: 9px;

        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background: #aaa;
        border-radius: 1px;
      }
    }
  }

  .popover-arrow:before {
    border-top-color: #dd3c10;
  }

  .popover-arrow:after {
    border-top-color: #ffebe8;
  }
}
