@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

$light-text: #a1a1a1;

.reorderSideCartItem {
  display: flex;
  justify-content: space-between;
  column-gap: 0.5em;
  min-height: 108px;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-bottom: 1px solid #e4e4e4;
  align-items: stretch;
  background-color: #fff;

  position: relative;

  .changingReorderSideCartItem {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    // The following 2 are used to keep the background from appearing over the title rows of the vendor rows in
    // combination with the padding at the top of the component, specified below based on viewport.
    background-size: cover;
    background-clip: content-box;
    z-index: 30;

    opacity: 0;
    transition:
      opacity 250ms ease-in 0ms,
      visibility 250ms ease-in 250ms;
    pointer-events: none;

    &.isCalculating {
      pointer-events: all;
      opacity: 1;
      visibility: visible !important;
      transition:
        opacity 250ms ease-in 50ms,
        visibility 250ms ease-in 0ms;
    }
  }

  &:first-child {
    border-top: 0px;
  }
  .quantityControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 2px;
    .quantity {
      margin-top: 6px;
      margin-bottom: 6px;
      font-size: 18px;
      font-weight: 500;
    }
    .quantityChanger {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // default opacity
      opacity: 0;
      cursor: pointer;
      &:before {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 6px;
        border-color: transparent;
        position: relative;
        border-bottom-color: rgb(67, 133, 246) !important;
        border-top-color: rgb(67, 133, 246) !important;
      }
      &.increase {
        border-top-width: 0px;
        top: -1px;
        &:before {
          border-top-width: 0px;
          border-bottom-color: #999;
          top: -1px;
        }
      }
      &.decrease {
        border-top-width: 0px;
        top: 1px;
        &:before {
          border-bottom-width: 0px;
          border-top-color: #999;
          top: 1px;
        }
      }
      &:not(:disabled):active {
        box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  &:hover {
    .quantityControls > .quantityChanger {
      opacity: 1;
    }
    .quantityControls .quantityChanger[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
    .itemDeleteContainer .itemDelete {
      opacity: 1;
    }
  }

  .productImageContainer {
    align-self: center;
    margin-right: 0.5em;
  }
  .cartDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5em;
    .innerDetailsContainer {
      display: flex;
      flex-direction: column;
      .productTitle {
        display: block;
        color: #353c43;
        max-height: 40px;
        overflow: hidden;
        &:hover {
          color: #4385f6;
        }
      }
      .productPricingContainer {
        display: flex;
        .productPrice {
          display: flex;
          margin-right: 0.5em;
          .price {
            font-weight: 600;
            font-size: 18px;
          }
          .each {
            align-self: flex-end;
            font-size: 14px;
            color: $light-text;
          }
        }
        .retailPrice {
          align-self: flex-end;
          color: $light-text;
          font-size: 12px;
          text-decoration: line-through;
        }
      }
    }
    .minMaxQtyContainer {
      display: flex;
      justify-content: flex-start;
      color: $light-text;
      font-size: 11px;
      padding-top: 0.5em;
      .minQuantity {
        margin-right: 1em;
      }
      .maxQuantity {
        margin-right: 1em;
      }
    }
  }
  .itemDeleteContainer {
    .deleteButton {
      display: inline-block;
      -webkit-appearance: none;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      outline: none;
      font-family: inherit;
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 3px;
      text-align: center;
      text-decoration: none !important;
      background-color: #4587f0;
      color: #fff !important;

      &:after,
      &:before {
        content: "";
        display: block;
        width: 10px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 3px;
      }
    }
    .itemDelete {
      border-radius: 50%;
      padding: 0px;
      height: 16px;
      width: 16px;
      border: 0px;
      position: relative;
      opacity: 0;
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
}
