@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

#buy-it-again-suggestions-container {
  @include media-breakpoint-down(lg) {
    visibility: hidden;
  }
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  margin-top: 1px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  padding: 16px 18px;
  z-index: 100;
  position: absolute;
  top: 40px;

  .buy-it-again-title {
    color: #2c2c2c;
    font-family: $circularStd;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
  .buy-it-again-suggestion {
    $maxWidth: 170px;
    width: $maxWidth;
    max-width: $maxWidth;

    overflow: hidden;

    &:visited {
      color: inherit;
    }
    &:hover {
      text-decoration: none;
    }

    &:not(:last-of-type) {
      padding-right: 10px;
    }
    .bia-image {
      $maxImageHeight: 141px;
      width: $maxWidth;
      height: $maxImageHeight;
      img {
        max-width: $maxWidth;
        max-height: $maxImageHeight;
        width: auto;
        height: auto;
      }
    }
    .bia-purchase-date {
      @include robotoBlack();
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
    }
    .bia-title {
      @include robotoBlack();
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;

      &:hover {
        text-decoration: underline;
        color: theme-color("primary");
      }
    }
    .bia-price {
      font-family: $roboto;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
    }

    .bia-retail-price {
      @include robotoBlack();
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
      min-height: 20px; // for spacing when the retail price is missing.
      text-decoration: line-through;
    }
  }
}
