@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

.checkout-subsection > h1 {
  margin: 10px 10px 4px 0;
  color: rgb(53, 60, 67);
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
}

@include media-breakpoint-down(md) {
  .checkout-subsection > h1 {
    margin-left: 10px;
  }
}

@include media-breakpoint-between(md, lg) {
  .checkout-subsection > h1 {
    margin-left: 10px;
  }
}
